﻿.filters {
	position: relative;
	margin: 0 calc-rem(-6px);
	z-index: 10;

	form {
		margin: calc-rem(8px) 0 calc-rem(40px);
	}

	.form-field {
		margin-bottom: calc-rem(14px);
	}

	.react-select {
		&__control {
			min-height: calc-rem(38px);
		}
	}

	&__route {
		position: relative;
		display: flex;
		align-items: center;
		z-index: 10;
	}

	&__additional {
		margin: calc-rem(16px) 0 calc-rem(32px);

		.filters__field {
			margin-bottom: calc-rem(2px);
		}

		label {
			flex: 1;
			font-size: calc-rem(14px);
			line-height: calc-rem(16px);
			font-weight: 400;
		}

		.react-select-wrapper {
			flex: 1;
			max-width: calc-rem(160px);
			font-size: calc-rem(14px);
		}
	}

	&__field {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__dash {
		flex: 1;
		height: 1px;
		border-bottom: 1px dashed $color-light-gray-3;
		margin: 0 calc-rem(12px) calc-rem(14px);
	}

	&__more {
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin: calc-rem(16px) 0;
	}

	&__button {
		@extend .btn-stripped, .btn-icon-left;
		color: $color-light-gray;
		padding-left: calc-rem(28px);

		.svg-inline {
			left: 0;
			stroke: $color-light-gray-2;
			font-size: calc-rem(22px);
			@include transition(stroke, 0.3s);;
		}

		&:hover {
			color: darken($color-light-gray, 40%);

			.svg-inline {
				stroke: darken($color-light-gray-2, 20%);
			}
		}
	}
}