.icon {
  &-blank {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &::after {
      content: "";
      @include absolute-center-center;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

	&__stamp {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc-rem(48px);
		height: calc-rem(48px);
		padding: calc-rem(10px);
		border-radius: $border-radius-circle;
		background-color: $color-light-purple;
		color: $color-primary;

		.svg-inline {
			font-size: calc-rem(24px);
		}

		&--linkedin {
			background-color: $color-blue;
			color: $color-white;
		}
		&--github {
			background-color: $color-yellow;
			color: $color-white;
		}
		&--instagram {
			background-color: $color-orange;
			color: $color-white;
		}

		&--md {
			width: calc-rem(40px);
			height: calc-rem(40px);
			padding: calc-rem(12px);
		}

		&--sm {
			width: calc-rem(36px);
			height: calc-rem(36px);
			padding: calc-rem(12px);
		}
	}
}