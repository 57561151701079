.header {
	position: relative;
  display: flex;
  align-items: center;
	justify-content: space-between;
  
  &__back {
		position: relative;
    height: calc-rem(48px);
    width: calc-rem(48px);
    border: none;
		z-index: 1;

    .svg-inline {
      stroke: $color-dark-gray;
			font-size: calc-rem(20px);
    }
  }

  &__current-page {
		position: absolute;
    left: 0;
    width: calc(100% - 6rem);
    text-align: center;
    margin: 0 calc-rem(48px);
    font-size: calc-rem(16px);
  }
}