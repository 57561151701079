$color-black: #000;
$color-white: #fff;

// Base colors
$color-purple: #8200ff;
$color-light-purple: #e6ceff;

$color-red: #e32c62;
$color-orange: #f56d20;
$color-yellow: #ffcd01;
$color-green: #7ada47;
$color-blue: #2d9fe0;

// Grays
$color-dark-gray: #101010;
$color-dark-gray-2: #464646;
$color-light-gray: #848386;
$color-light-gray-2: #ababad;
$color-light-gray-3: #dedede;
$color-light-gray-4: #f7f7f7;

// Primary color
$color-primary: $color-purple;

// Information
$color-error: $color-red;
$color-warning: $color-orange;
$color-success: $color-green;
$color-info: $color-blue;

$colors: (
	'primary': $color-primary,
	'black': $color-black,
	'white': $color-white,
	'red': $color-red,
	'orange': $color-orange,
	'yellow': $color-yellow,
	'green': $color-green,
	'blue': $color-blue,
	'gray': $color-dark-gray-2,
	'gray-light': $color-light-gray,
	'error': $color-error,
	'warning': $color-warning,
	'success': $color-success,
	'info': $color-info,
);

$colors-message: (
	error: $color-error,
	warning: $color-warning,
	success: $color-success,
	info: $color-info,
);