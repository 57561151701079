@mixin create-link-color(
  $color: $color-primary,
  $hover-color: $color-black,
) {
  color: $color;

  &:hover {
    color: $hover-color;
  }
}

$arrow-base: 16px;

a {
	display: inline-block;
	font-size: calc-rem(16px);
	position: relative;
  @include create-link-color($color-dark-gray-2, $color-primary);
  @include transition(color, 0.3s);
}

.link-register {
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-top: calc-rem(32px);
}
