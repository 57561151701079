ul {
  list-style: none;
}

ol {
  margin-left: calc-rem(14px);
}

.list {
  li {
    position: relative;
    margin: calc-rem(8px) 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
  }
}

.inline-list {
  display: flex;

	li {
		margin-left: calc-rem(16px);
		margin-right: calc-rem(16px);
	}
}

.list-menu {
	@extend .list;
	margin: calc-rem(32px) calc-rem(-20px);

	li {
		margin: calc-rem(6px) 0;
		padding: calc-rem(10px) calc-rem(20px);
		background-color: $color-light-gray-4;
	}

	&__item {
		display: flex;
		align-items: center;
		color: $color-dark-gray-2;
	}

	span {
		margin-left: calc-rem(24px);
	}
}

.list-desc {
  &__columns,
  &__rows {
    dt, dd {
			font-size: calc-rem(14px);
      margin: calc-rem(8px) calc-rem(16px);
    }
  
    dt {
			color: $color-light-gray
    }
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
		margin: 0 calc-rem(-20px);
  }

  &__row {
    display: flex;
    align-items: center;
		margin: 0 calc-rem(-16px);

    dt, dd {
      flex: 1;
			margin-top: calc-rem(6px);
			margin-bottom: calc-rem(6px);
    }
  }

  &__col {
    flex: 1;
  }

  &--double {
    flex: 2
  }

	&--sm {
		dd {
			margin-top: calc-rem(6px);
			margin-bottom: calc-rem(6px);
		}
	}
}

.list__users {
	&-description {
		margin-left: calc-rem(16px);

		h5 {
			font-size: calc-rem(16px);
			line-height: 1.2;
		}

		p {
			font-size: calc-rem(14px);
			color: $color-light-gray;
			line-height: 1.2;
		}
	}

  li {
    display: flex;
    align-items: center;
    margin: calc-rem(12px) 0;
    font-size: calc-rem(14px);

		span {
			margin-left: calc-rem(12px);
		}
  }
}