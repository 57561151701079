.branding {
  &__logo {
    width: calc-rem(48px);
    height: calc-rem(48px);
    padding: calc-rem(10px);
		color: $color-primary;

		.svg-inline {
			width: 100%;
			height: 100%;
		}
  }

  &-auth {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: calc-rem(16px);
    margin-bottom: calc-rem(60px);

    &__logo {
      width: calc-rem(40px);
    }

    &__moto {
      margin-top: calc-rem(14px);
      font-weight: 400;
    }
  }
}