.react-select-wrapper {
    .react-select {
        &__control {
            background-color: $color-white;
            border: 1px solid $color-light-gray-3;
            border-radius: calc-rem(18px);
            min-height: calc-rem(53px);
						margin: calc-rem(4px) 0;

						&--is-focused {
							box-shadow: 0 0 0 1px $color-primary;
						}

						&:hover {
							border-color: $color-primary;
						}
        }
    
        &__single-value {
            color: $color-dark-gray;
        }
    
        &__input[type="text"] {
            box-shadow: none;
        }

        &__input-container {
            color: $color-dark-gray;
        }

				&__option--is-selected {
					background-color: $color-primary;
				}
    }
}