.card__ride {
  &-title {
		color: $color-dark-gray-2;
    font-weight: 600;
  }

	.card__price {
		margin-top: calc-rem(16px);
	}
}

.card__ride-description {
	.card {
		&__header {
			justify-content: space-between;
		}
	}
}