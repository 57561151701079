.react-tabs__tab {
	padding: calc-rem(8px) calc-rem(16px);
	color: $color-light-gray;
	background-color: transparent;
	font-family: $font-montserrat;
	font-size: calc-rem(14px);
	font-weight: 600;
	text-align: center;
	border-radius: $border-radius-pill;
	border: 2px solid $color-light-gray;
	cursor: pointer;

	&--selected {
		border-color: $color-primary;
		color: $color-primary;
		outline: none;
	}

	&-list {
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin-bottom: calc-rem(20px);
	}
}