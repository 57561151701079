.card {
  position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: calc-rem(20px);
	background-color: var(--card-bg);
	border-radius: calc-rem(38px);

	--card-bg: #{$color-light-gray-4};

	&__wrapper {
		.card {
			margin: calc-rem(20px) 0;
		}
	}

	&__header {
		position: relative;
    display: flex;
    align-items: center;

    &-dash {
      flex: 1;
      border-bottom: 1px dashed $color-white;
      margin: 0 calc-rem(10px);
    }
  
    &-title {
      line-height: calc-rem(22px);
      font-weight: 600;
    }

		&-description {
			color: $color-light-gray;
			font-weight: 500;
		}

		&--stats {
			padding-right: calc-rem(32px);
		}
	}

	&__body {
		margin-top: calc-rem(10px);
	}

	&__section {
		margin: calc-rem(10px) 0;
		
		&--flex {
			display: flex;
			align-items: center;
			gap: calc-rem(28px);
		}
	}

	&__price {
		color: $color-dark-gray-2;
		font-weight: 600;
		line-height: 1;
	}

	&--ribbon {
		&-right,
		&-top {
			overflow: hidden;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				background: $color-primary;
			}
		}

		&-right {
			border-top-right-radius: calc-rem(10px);
			border-bottom-right-radius: calc-rem(10px);

			&::before {
				height: 100%;
				width: calc-rem(12px);
			}
		}

		&-top {
			border-top-left-radius: calc-rem(10px);
			border-top-right-radius: calc-rem(10px);

			&::before {
				height: calc-rem(6px);
				width: 100%;
			}
		}
	}

  &--light {
    --card-bg: #{$color-light-gray-4};
	}
}
