.saved-driver {
  position: relative;
  margin: 0 calc-rem(4px);

  &__wrapper {
    margin-top: calc-rem(6px);
    margin-bottom: calc-rem(6px);
  }

  &__link {
    position: absolute;
    top: 0;
    left: calc-rem(-40px);
    width: calc(100% + calc-rem(80px));
    height: 100%;
  }

  .thumbnail__user {
    background-color: $color-light-gray-2;
    border-radius: $border-radius-xl;

    .svg-inline {
      fill: rgba($color-black, .5);
    }
  }

  p {
    color: $color-light-gray;
  }
}