form {
	margin: calc-rem(16px) 0;
}

.form {
	&-group {
		display: flex;
		flex-flow: column;
	}

	&-field {
		margin-bottom: calc-rem(16px);

		label {
			display: block;
			margin-bottom: calc-rem(6px);
		}

		&--disabled {
			label {
				color: lighten($color-light-gray, 20%)
			}
		}
		
		&__icon {
			position: relative;

			.svg-inline {
				position: absolute;
				left: calc-rem(20px);
				top: 50%;
				transform: translateY(-50%);
				stroke: $color-light-gray-2;
				pointer-events: none;
				font-size: calc-rem(20px);
			}

			input {
				padding-left: calc-rem(56px);
			}
		}

		&__autocomplete,
		> input {
			flex: 1;
		}

		&__autocomplete input {
			@include input-placeholder {
				opacity: 1;
			}
		}

		&__map-input {
			display: flex;
			align-items: center;
			margin-bottom: calc-rem(4px);
			gap: calc-rem(24px);

			label {
				position: relative;
				margin: 0;
		
				&::before,
				&::after {
					content: '';
					position: absolute;
				}
			}
		}

		&__map-filters {
			max-width: calc-rem(124px);
	
			input {
				padding-left: calc-rem(36px);
			}

			label {
				position: absolute;
				left: calc-rem(12px);

				&::before {
					content: none;
				}
			}
		}

		&__origin-label,
		&__destination-label {
			width: calc-rem(10px);
			height: calc-rem(10px);

			&::before {
				left: calc-rem(4px);
				width: calc-rem(2px);
				height: calc-rem(40px);
				background: $color-light-gray-3;
			}
			
			&::after {
				width: 100%;
				height: 100%;
				background-color: $color-primary;
			}
		}

		&__origin-label {
			&::before {
				top: 0;
			}
			
			&::after {
				border-radius: $border-radius-circle;
			}
		}
		
		&__destination-label {
			&::before {
				bottom: 0;
			}
			
			&::after {
				transform: rotate(45deg) scale(0.9);
			}
		}

		&__waypoint-label {
			width: calc-rem(14px);
			height: calc-rem(14px);
			margin-bottom: 0;
			
			&::after {
				width: 100%;
				height: 100%;
				background-image: $icon-marker;
				background-size: contain;
			}
		}
	}

	&-footer {
		margin-top: calc-rem(25px);
		align-items: center;
	}
}