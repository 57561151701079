.map {
  &__wrapper {
		position: relative;
		margin: calc-rem(16px) calc-rem(-24px);
  }

  &__distance {
    position: absolute;
    text-align: center;
    top: calc-rem(12px);
		width: 100%;
		display: flex;
		justify-content: center;
		pointer-events: none;

		p {
			font-weight: 500;
			padding: calc-rem(4px) calc-rem(18px);
			color: $color-primary;
			background-color: $color-white;
			border: 2px solid $color-primary;
			border-radius: $border-radius-xl;
		}
  }
  
  &__route {
    margin: calc-rem(16px) 0;
  }
}

.gm-style-iw {
  &-c {
    padding: calc-rem(20px) !important;
    max-width: calc-rem(280px) !important;
  }

  &-d {
    overflow: initial !important;
  }

	&-ch {
		font-family: $font-montserrat;
		font-weight: 600;
		font-size: calc-rem(16px);
		color: $color-dark-gray-2;
		padding-top: calc-rem(4px);
		
		&r {
			margin-bottom: calc-rem(16px);
		}
	}
}

.gm-ui-hover-effect {
  opacity: 1;
  border-radius: 50%;
  background-color: $color-white !important;
  top: calc-rem(-10px) !important;
  right: calc-rem(-6px) !important;
  width: calc-rem(36px) !important;
  height: calc-rem(36px) !important;

  span {
    width: calc-rem(20px) !important;
    height: calc-rem(20px) !important;
  }
}

.info-window {
  &__content {
    p {
      line-height: calc-rem(22px);
    }

		.svg-inline {
      font-size: calc-rem(28px);
    	stroke: $color-primary;
    }
  }

	&__main,
  &__estimations {
    display: flex;
    align-items: center;
  }

  &__main {
    margin-bottom: calc-rem(22px);
  }
	
	&__estimations {
		max-width: calc-rem(110px);
	}

  &__footer {
    display: flex;
    justify-content: space-between;
    gap: calc-rem(20px);
  }

  &__info {
		margin-left: calc-rem(16px);

    h6 {
      color: $color-dark-gray-2;
    }

		p {
			color: $color-light-gray;
		}
  }
}

.gm-ui-hover-effect > span {
	margin: calc-rem(9px);
}