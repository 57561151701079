// GLOBAL TEXT-LIKE INPUTS
%global-field-style {
    width: 100%;
    color: $color-dark-gray;
    background-color: $color-white;
    border: 1px solid $color-light-gray-3;
    border-radius: calc-rem(18px);
    padding: calc-rem(16px);
		margin: calc-rem(4px) 0;
    font-family: inherit;
		letter-spacing: -.5px;
    font-size: calc-rem(16px);
    outline: none;
    cursor: text;
    touch-action: manipulation;
    @include transition(all, 0.3s);
  
    &.error {
      border-color: $color-error;
    }
  
    &.success {
      border-color: $color-success;
    }
  
    &[readonly] {
      background-color: $color-light-gray;
      color: darken($color-dark-gray, 10%);
    }

		&[disabled],
		&.disabled {
			border-color: rgba($color-light-gray-3, .5);
			color: $color-light-gray-2;
			background-color: $color-light-gray-4;	
		}

		@include input-placeholder {
			color: $color-light-gray-2;
			opacity: 0;
			transition: inherit;
		}

		&:focus {
			border-color: $color-primary;
			
			@include input-placeholder {
				opacity: 1;
			}
		}
  }
  
  // RADIO AND CHECKBOX BASE
  @mixin radio-checkbox($box-width, $radius) {
    .input-wrapper {
      display: flex;
      align-items: center;
      min-height: calc-rem(46px);
    }
  
		& {
			z-index: 0;
		}
  
    label {
      position: relative;
      padding-left: calc-rem(44px);
      margin: 0;
      color: $color-dark-gray-2;
      cursor: pointer;
  
      &::before {
        content: "";
        position: absolute;
        box-sizing: border-box;
        z-index: 1;
        @include transition(transform, 0.25s);
      }
  
      &::after {
        content: "";
        @include absolute-left-center;
        border-radius: $radius;
        width: $box-width;
        height: $box-width;
        background-color: transparent;
        border: 1px solid $color-light-gray-2;
        box-sizing: border-box;
        @include transition(all, 0.25s);
      }
    }
  
    input[type="radio"],
    input[type="checkbox"] {
      display: none;
      opacity: 0;
  
      &[disabled] + label {
        cursor: not-allowed;
        color: lighten($color-light-gray-2, 26%);
  
        &::after {
          border-color: lighten($color-light-gray-2, 26%);
        }
      }
    }
  }