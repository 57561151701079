.profile {
	@extend %background-pseudo-text;

	&__img {
		position: relative;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: calc-rem(130px);
		height: calc-rem(130px);
		background-color: $color-light-gray-3;
		border-radius: $border-radius-md;
		box-shadow: 0 calc-rem(4px) calc-rem(1px) rgba($color-black, 0.25);
		overflow: hidden;
		cursor: pointer;

		img {
			height: 100%;
		}
	}

	&__file {
		&-input {
			display: none;
		}

		&-change {
			position: absolute;
			bottom: 0;
			width: 100%;
			text-align: center;
			padding: calc-rem(10px) 0;
			background-color: rgba($color-light-gray-2, 40%);
			font-weight: 700;
			font-size: calc-rem(12px);
			line-height: 1;
			color: $color-white;
		}
	}

	&__svg-wrapper {
		padding: calc-rem(20px);
		height: 100%;

		.svg-inline {
			height: 100%;
			width: 100%;
			fill: $color-light-gray-2;
		}
	}

  &__name {
    margin: calc-rem(16px) 0;
    text-align: center;
  }

	&__routes {
		dt {
			font-weight: 400;
		}

		dd {
			font-size: calc-rem(20px);
			font-weight: 700;
			margin-top: calc-rem(4px);
		}
	}
}
