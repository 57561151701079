.sidemenu {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	z-index: 999;

	&__overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba($color-dark-gray-2, 0.5);
		z-index: -1;
	}

	&__header {
		margin-bottom: calc-rem(20px);

		.btn-menu {
			margin-left: auto;
		}
	}

	&__content {
		width: calc(100% - 60px);
		height: 100%;
		margin-left: auto;
		background: $color-white;
		padding: calc-rem(20px);
	}

	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: calc-rem(20px);

		.inline-list {
			justify-content: center;
		}

		a:hover {
			color: $color-dark-gray
		}
	}
}