.svg-inline {
  display: inline-block;
  font-size: calc-rem(16px);
  height: 1em;
  vertical-align: middle;
  @include transition(fill, .25s);
}

.svg-outlined {
	fill: none;
	stroke: currentColor;
	stroke-linecap: round;
	stroke-linejoin: round;
}